import React, { useState } from 'react';
import Modals from '@/components/Modals';

export const modalContext = React.createContext({});

const emptyModal = (props) => {
  return <></>;
};

// Note one limitation of this implementation is it cannot open 2 of the same Modal sequentially. For example, if a ConfirmationModal is loaded
// and the user confirm, and upon confirmation there needs to show some other kind of ConfirmationModal, that will not work. It's because Modals
// are loaded according to their name in modals/index.js. So, it can only sequentially load Modals that have different names.
export const ModalProvider = ({ children }) => {
  const [activeModal, setActiveModal] = useState(null);
  const [modalQueue, setModalQueue] = useState([]);
  const Modal = activeModal ? Modals[activeModal.name] : emptyModal;

  const enqueueModal = (modalConfig, { forceNext, forceOpen } = {}) => {
    if (!activeModal || forceOpen) {
      setActiveModal((prevState) => {
        if (forceOpen || !prevState) {
          return modalConfig;
        } else {
          setModalQueue((prevQueue) => [...prevQueue, modalConfig]);
          return prevState;
        }
      });
    } else if (forceNext === true) {
      setModalQueue((prevQueue) => [modalConfig, ...prevQueue]);
    } else {
      setModalQueue((prevQueue) => [...prevQueue, modalConfig]);
    }
  };

  // TODO: pass in a modal name and close the modal that matches the name
  const closeModal = () => {
    setActiveModal((prevState) => {
      return prevState === null ? prevState : { ...prevState, show: false };
    });
  };

  const loadNextModal = () => {
    if (modalQueue.length) {
      setActiveModal(modalQueue[0]);
      setModalQueue(modalQueue.slice(1));
    } else {
      setActiveModal(null);
    }
  };

  return (
    <modalContext.Provider
      value={{
        enqueueModal: enqueueModal,
        closeModal: closeModal
      }}
    >
      <>
        <div key="modalSibling">{children}</div>
        <Modal {...activeModal} onExited={loadNextModal} />
      </>
    </modalContext.Provider>
  );
};
