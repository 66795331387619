/* During the migration of the platform to React, parts of the application exist in both Angular and React
 * linking between the two frameworks requires a few extra considerations so this component is a
 * temporary helper to be used in place of NextJS `next/link` to account for how a link should be
 * executed based on which platform the destination exists.  This will serve as an extension of `next/link`
 * so it can be easily search+replaced later
 */
import React from 'react';
import NextLink from 'next/link';
import { pathsInReact } from '@/utils/helpers';
import classes from './Link.module.scss';

export const isReactPage = (href) => {
  let match = false;
  try {
    let url = new URL(href, window?.location?.origin || process.env.PUBLIC_URL);
    pathsInReact.forEach((pathRegex) => {
      match = match || pathRegex.test(url.pathname);
    });
    return match;
  } catch (error) {
    return false;
  }
};

const Link = ({ children, className, href, target = '_self', ...props }) => {
  return (
    <>
      {isReactPage(href) ? (
        <NextLink href={href} target={target} {...props} className={`${classes.link} ${className || ''}`}>
          {children}
        </NextLink>
      ) : (
        <a className={`${classes.link} ${className}`} href={href} target={target}>
          {children}
        </a>
      )}
    </>
  );
};

export default Link;
