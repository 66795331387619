import { useState, useContext, useEffect } from 'react';
import { modalContext } from '@/context/modal.context';
import { toastContext } from '@/context/toast.context';
import { AccountContext } from '@/context/account.context';
import { LocationContext } from '@/context/location.context';
import { localStorageService, sessionStorageService } from '@/utils/local_storage';
import useLocalStorageSession from '@/hooks/useLocalStorageSession';
import { useIdleTimer } from 'react-idle-timer';
import { useRouter } from 'next/router';
import { bookmarkHelpers } from '../utils/helpers';
import useMixpanel from '@/hooks/useMixpanel';
import Auth from '@/utils/api/odas/auth';
import Language from '@/utils/Language';
import isEmail from 'validator/lib/isEmail';
import t from '@/utils/Language/Modal/LoginModal-translations';
import useHoneybadger from '@/hooks/useHoneybadger';
import { emailOrPhone } from '@/utils/utils';
import { loadScript } from '@/utils/utils';
import Keys from '@/constants/Keys';
import publicRoutes from '@/public-routes.json';
import { isReactPage } from '@/helpers/Link';

const useAccount = () => {
  const modal = useContext(modalContext);
  const { addToast } = useContext(toastContext);
  const account = useContext(AccountContext);
  const locationContext = useContext(LocationContext);
  const { setSessionValue, deleteSessionValue } = useLocalStorageSession();
  const router = useRouter();
  const { track } = useMixpanel();
  const loginProvider = 'one-degree'; // used for Mixpanel tracking
  const defaultOptions = { timeout: 1000 * 60 * 20, disable: false };
  const { setHoneybadgerContext } = useHoneybadger();

  useEffect(() => {
    let providerCookie = Auth.getProvider();
    if (providerCookie) {
      setSessionValue('provider', providerCookie);
      // initialize a new saml session on the first page-load
      if (router?.query?.samlinit && Auth.getToken()) {
        const { samlinit, ...queryWithoutParam } = router.query;
        router.replace({ query: queryWithoutParam }, undefined, { shallow: true });
        Auth.getAccountInfo()
          .then((data) => {
            processLogin(data, router.pathname.indexOf('/onboarding-saml') === 0);
          })
          .catch((err) => {
            addToast({
              body: t('You might not have an account yet. Please try signing up instead.'),
              delayed: 10000
            });
          });
      }
    }
  }, []);

  useEffect(() => {
    if (Auth.isLoggedIn() && Auth.sessionIsExpired) {
      account.setState({ isLoggedIn: false });
      if (router.pathname != '/confirm-login') {
        confirmSessionModal();
      }
    }
  }, [Auth.sessionIsExpired]);

  const getAffiliation = () => {
    return Auth.getAffiliationInfo()
      .then((data) => {
        if (data) {
          account.setAffiliationInfo(data.user?.affiliation);
        }
      })
      .catch((error) => {
        console.error('error', error);
        addToast({
          body: t('You might not have an account yet. Please try signing up instead.'),
          delayed: 10000
        });
        // setMessage(error?.message);
      });
  };

  const login = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target),
      formDataObj = Object.fromEntries(formData.entries());
    return Auth.login({ login_key: formDataObj.email, email: formDataObj.email, password: formDataObj.password })
      .then((data) => {
        return processLogin(data);
      })
      .catch((error) => {
        addToast({
          body: t('You might not have an account yet. Please try signing up instead.'),
          delayed: 10000
        });
      });
  };

  const confirmSessionModal = () => {
    modal.enqueueModal({ name: 'ReloginModal' }, { forceOpen: true });
  };

  const confirm = (e) => {
    if (e) {
      e.preventDefault();
      const formData = new FormData(e.target),
        formDataObj = Object.fromEntries(formData.entries());
      return Auth.confirm({ password: formDataObj.password })
        .then((data) => {
          if (data) {
            account.storeUserInfo(data.user);
            Auth.sessionIsExpired = false;
            modal?.closeModal();
            account.setState({ isLoggedIn: true });
            if (router.query.r) {
              window.location.href = router.query.r;
            }
          } else {
            addToast({
              body: t('We could not confirm you own this account. Please re-enter your password and try again.'),
              delayed: 10000
            });
          }
        })
        .catch((error) => {
          addToast({
            body: t('We could not confirm you own this account. Please re-enter your password and try again.'),
            delayed: 10000
          });
        });
    }
  };

  const signup = (e, args) => {
    e.preventDefault();
    const formData = new FormData(e.target),
      formDataObj = Object.fromEntries(formData.entries());
    const loginKey = isEmail(formDataObj.emailOrPhone) ? 'email' : 'cell_phone';
    const user = { password: formDataObj.password, is_professional: !!formDataObj.is_professional };
    user[loginKey] = formDataObj.emailOrPhone;
    return Auth.signup(user, args).then((data) => {
      if (data) {
        return processSignup(data);
      } else {
        return false;
      }
    });
  };

  const clearUser = () => {
    const p = Auth.logoutUser();
    localStorage.setItem('user', JSON.stringify({})); // TODO consider removing this in late 2023. This is needed for now due to us storing directly in `user` in local storage. But going forward we should be using our localStorage service which prepends `.ls` which means eventually we should only be storing in `ls.user`
    return p;
  };

  const logout = () => {
    const path = router.asPath.split('?')[0].split('/')[1];
    deleteSessionValue('provider');
    bookmarkHelpers.clearBookmarkCache();
    account.setAccessEvents([]);
    account.setBookmarks({});
    account.storeUserInfo({});
    account.setState({ isLoggedIn: false });
    setHoneybadgerContext({});
    if (!publicRoutes.routes.includes(path)) {
      window.location.href = '/';
    }
    sessionStorageService.clear();
    return clearUser();
  };

  const handleLoginWithGooglePlus = (authResponse) => {
    return Auth.handleLoginWithGooglePlus(authResponse).then((data) => {
      if (data.isCreation) {
        return processSignup(data);
      } else {
        return processLogin(data);
      }
    });
  };

  const displayGoogleButton = (el, customCallback) => {
    if (typeof customCallback !== 'function') {
      customCallback = (result) => result;
    }
    return loadScript('//accounts.google.com/gsi/client')
      .then((res) => {
        // Auth.gAPILoaded = true;
        return google.accounts.id.initialize({
          client_id: Keys.googlePlus.clientId,
          callback: (authResponse) => {
            handleLoginWithGooglePlus(authResponse).then(customCallback);
          }
        });
      })
      .then(() => {
        google.accounts.id.renderButton(document.getElementById(el), {
          theme: 'outline',
          type: 'standard',
          shape: 'pill',
          text: 'continue_with',
          size: 'large',
          logo_alignment: 'center',
          width: '300px',
          locale: Language.getCurrentLanguage()
        });
      });
  };

  const processLogin = (data, skipRedirect) => {
    if (data) {
      if ([400, 401, 404].includes(data?.response?.status)) {
        const message = t(data.response.data?.message) || t('You might not have an account yet. Please try signing up instead.');
        addToast({
          body: message,
          delayed: 10000
        });
        return false;
      }
      account.setState({ isLoggedIn: true });
      account.storeUserInfo(data.user);
      getAffiliation();
      setHoneybadgerContext({
        user_id: data?.user?.id
      });
      track('Login', { provider: loginProvider });
      if (data.user?.latitude && data.user?.longitude) {
        locationContext.updateLocation({
          locationName: data.user.location,
          lat: data.user.latitude,
          lng: data.user.longitude,
          state: data.user.state,
          county: data.user.county
        });
      }
      if (data.user?.region) {
        locationContext.updateLocation({
          region: data.user.region
        });
      }
    }
    if (!skipRedirect) {
      openNextPage();
    }
    return !!data;
  };

  const processSignup = (data) => {
    if ([400, 401, 404].includes(data?.response?.status)) {
      const message = t(
        "We couldn't create your account. Make sure you are using a real email address or cell phone number. If you think you already have an account, you can try logging in."
      );
      addToast({
        body: message,
        delayed: 10000
      });
      return false;
    }
    // modal box for affiliation

    track('user_created', { provider: loginProvider });
    account.setState({ isLoggedIn: true });
    account.storeUserInfo(data?.user);
    setHoneybadgerContext({
      user_id: data?.user?.id
    });
    return data;
  };

  const openNextPage = () => {
    const nextPage = account.nextPage;
    const nextPageTarget = account.nextPageTarget;
    account.setNextPage(false);
    account.setNextPageTarget(false);
    if (nextPage) {
      let decodedNextPage = decodeURIComponent(nextPage);
      if (nextPageTarget) {
        window.open(decodedNextPage, nextPageTarget);
      } else {
        if (isReactPage(decodedNextPage)) {
          router.push(decodedNextPage);
        } else if (window) {
          window.location.href = decodedNextPage;
        }
      }
    } else if (router.query.r) {
      window.location.href = router.query.r;
    }
    // on these pages we set nextPage but there is something about Google auth flow that unsets the
    // state variable in the account context. So here we handle that case specifically.
    else if (router.pathname == '/login' || router.pathname == '/signup') {
      window.location.href = '/';
    }
    modal?.closeModal();
  };

  const setLocation = (e) => {
    return () => {
      let userObj = localStorageService.get('user') || {};
      userObj = Object.assign(userObj, { locale: e ? e.language : 'en' }, locationContext.getLocation() || {});
      if (userObj.location) {
        return account.updateAccountInfo(userObj);
      }
      return null;
    };
  };

  const forgotPassword = (e) => {
    let formDataObj = e;
    if (e.target) {
      e.preventDefault();
      const formData = new FormData(e.target);
      formDataObj = Object.fromEntries(formData.entries());
    }
    const emailOrPhoneKey = emailOrPhone(formDataObj.emailOrPhone);
    return emailOrPhoneKey
      ? Auth.reset({ [emailOrPhoneKey]: formDataObj.emailOrPhone }).then((data) => {
          return 'success';
        })
      : null;
  };

  return {
    account: account,
    login: login,
    signup: signup,
    logout: logout,
    confirm: confirm,
    openNextPage: openNextPage,
    confirmSessionModal: confirmSessionModal,
    displayGoogleButton: displayGoogleButton,
    setLocation: setLocation,
    forgotPassword: forgotPassword
  };
};

export const useReAuth = (timerOptions) => {
  const router = useRouter();
  const defaultOptions = { timeout: 1000 * 60 * 20, disable: false };

  const timeout = timerOptions?.timeout || defaultOptions.timeout;
  const disable = timerOptions?.disable || defaultOptions.disable;

  const onIdle = (disabled) => {
    if (disabled) {
      return;
    }
    if (Auth.isLoggedIn() && !Auth.sessionIsExpired) {
      Auth.expireSession();
      window.location.href = Auth.getUrlFor('confirm-login');
    }
  };
  return useIdleTimer({
    onIdle: () => onIdle(disable),
    timeout: timeout
  });
};

export default useAccount;
