import lang, { withTranslations } from '@/utils/Language';
import ui from '@/assets/i18n/ui';
import form from '@/assets/i18n/forms';
import saml from '@/assets/i18n/features/saml';

const translations = {
  en: Object.assign({}, ui.en, form.en, saml.en, {
    'Forgot password or never set one': 'Forgot password or never set one?',
    'Log in to save resources.': 'Log in to save resources.',
    'Log in with Google': 'Log in with Google',
    'Sign up with Google': 'Sign up with Google',
    'Need an account?': 'Need an account?',
    'Sign up.': 'Sign up.',
    'Email or cell phone': 'Email or cell phone',
    Password: 'Password',
    'Email is required': 'Email is required',
    'Email or cell phone is required': 'Email or cell phone is required',
    'Password is required': 'Password is required',
    _title: 'Sign up to save resources.',
    _subtitle: ' ',
    "It's free and secure!": "It's free and secure!",
    'Safely upload and store your important documents.': 'Safely upload and store your important documents.',
    'Save resources to view later.': 'Save resources to view later.',
    or: 'or',
    login_title: 'Login into your One Degree account.',
    login_subtitle: ' ',
    share_title: 'Make sharing easier',
    share_subtitle: 'Create a free One Degree account to save and share resources quickly and easily in the future',
    print_title: 'Signup to print an opportunity',
    print_subtitle: "To print, please sign up or login. It's free!",
    refer_title: 'Signup to refer an opportunity',
    refer_subtitle: "To start a referral, please sign up or login. It's free!",
    sharing_title: 'Make sharing easier',
    sharing_subtitle: 'Create a free One Degree account to save and share resources quickly and easily in the future.',
    track_title: 'Sign up to keep track',
    track_subtitle: 'Create a free account to start keeping track of your access to resources.',
    forgot_password_title: 'Reset password',
    forgot_password_subtitle: "We'll send you an email or text message to reset your password.",
    newCollection_title: 'Create a new collection',
    newCollection_subtitle: 'You need to login to create a new collection.',
    assessmentLogin_title: 'Login',
    assessmentLogin_subtitle: 'Login to your One Degree account to continue.',
    assessmentSignup_title: "You're done! Sign up to see your results",
    assessmentSignup_subtitle: 'Create a free One Degree account to let us check your results and give you suggestions on what to do next.',
    Send: 'Send',
    'at least': 'At least:',
    '8 characters': '8 characters',
    '1 letter': '1 letter',
    '1 number': '1 number',
    '1 special character': '1 special character',
    password: 'Password',
    'Already have an account?': 'Already have an account?',
    'Log in': 'Log in',
    'Create an account': 'Create an account',
    'Finish account setup': 'Finish account setup',
    'I work at a nonprofit or government agency': 'I work at a nonprofit or government agency',
    'Sign up with your work email': 'Sign up with your work email',
    'Signup legal disclaimer':
      "We keep your information confidential. You will be asked if we ever need to share your information with any partners. We never sell your data. Here's our <a href='${privacy_policy_link}' target='_blank'>Privacy Policy</a> and <a href='${tou_link}' target='_blank'>Terms of Use</a>, which you agree to by creating an account.",
    "Let's set up the details of your account": "Let's set up the details of your account",
    'Set your location (required)': 'Set your location (required)',
    'Location (required)': 'Location (required)',
    Location: 'Location',
    'Skip for now': 'Skip for now',
    'We use your location to show relevant resources near you': 'We use your location to show relevant resources near you',
    Language: 'Language',
    English: 'English',
    Spanish: 'Spanish',
    Continue: 'Continue',
    set_organization_title: "Let's set up the details of your account",
    set_organization_subtitle: ' ',
    set_organization_dhs_title: 'Where do you do most of your work?',
    set_organization_dhs_subtitle: 'Select one even if you work in multiple places.',
    'Gender (optional)': 'Gender (optional)',
    Male: 'Male',
    Female: 'Female',
    'Transgender Man': 'Transgender Man',
    'Transgender Woman': 'Transgender Woman',
    'A gender not listed here': 'A gender not listed here',
    'Prefer not to say': 'Prefer not to say',
    'Let me specify': 'Let me specify',
    'Date of birth (optional)': 'Date of birth (optional)',
    'First name': 'First name',
    'Last name': 'Last name',
    'Cell Phone': 'Cell Phone',
    'Cell Phone (optional)': 'Cell Phone (optional)',
    'MM/DD/YYYY': 'MM/DD/YYYY',
    'Account created successfully.': 'Account created successfully.',
    'The passwords should match': 'The passwords should match',
    'Re-enter password': 'Re-enter password',
    'Forgot password?': 'Forgot password?',
    "Don't have an account?": "Don't have an account?",
    'Check your inbox or cell phone for instructions on how to reset your password. If you do not receive an email or text message from us, you may need to check your Spam folder or create a new One Degree account.':
      'Check your inbox or cell phone for instructions on how to reset your password. If you do not receive an email or text message from us, you may need to check your Spam folder or create a new One Degree account.',
    'Please check your email or cell phone and try again.': 'Please check your email or cell phone and try again.',
    'Which of the following genders best represents how you think of yourself?':
      'Which of the following genders best represents how you think of yourself?',
    Organization: 'Organization',
    'Organization (Start typing...)': 'Organization (Start typing...)',
    Position: 'Position',
    'Type to search...': 'Type to search...',
    'Searching...': 'Searching...',
    'No matches found.': 'No matches found.',
    Facilities: 'Facilities',
    'Main Agency': 'Main Agency',
    "We couldn't create your account. Make sure you are using a real email address or cell phone number. If you think you already have an account, you can try logging in.":
      "We couldn't create your account. Make sure you are using a real email address or cell phone number. If you think you already have an account, you can try logging in.",
    'You might not have an account yet. Please try signing up instead.': 'You might not have an account yet. Please try signing up instead.',
    'Too many failed login attempts. Please confirm CAPTCHA and then try again.':
      'Too many failed login attempts. Please confirm CAPTCHA and then try again.',
    'signup-error:email': 'The email address you have entered has already been taken. Please use a different email address.',
    'signup-error:cell phone': 'The phone number you have entered has already been taken. Please use a different phone number.'
  }),
  es: Object.assign({}, ui.es, form.es, saml.es, {
    'Forgot password or never set one': '¿Olvidó su contraseña o no tiene una?',
    'Log in to save resources.': 'Inicie sesión para guardar recursos.',
    'Log in with Google': 'Iniciar sesión con Google',
    'Sign up with Google': 'Cree una cuenta con Google',
    'Need an account?': '¿Necesita una cuenta?',
    'Sign up.': 'Registrarse.',
    'Email or cell phone': 'Correo electrónico o teléfono celular',
    Password: 'Contraseña',
    'Email is required': 'Se requiere correo electrónico',
    'Email or cell phone is required': 'Se requiere correo electrónico o teléfono celular',
    'Password is required': 'Se requiere contraseña',
    _title: 'Regístrate para ahorrar recursos',
    _subtitle: ' ',
    "It's free and secure!": 'gratuito y seguro!',
    'Safely upload and store your important documents.': 'Carga y guarda tus documentos importantes de manera segura',
    'Save resources to view later.': 'Guarda recursos para verlos más tarde fácilmente',
    or: 'o',
    login_title: 'Inicie sesión con su cuenta de One Degree.',
    login_subtitle: ' ',
    share_title: 'Haga compartir más facil',
    share_subtitle: 'Crear una cuenta gratuita para ahorrar y compartir recursos rápido y fácilmente en el futuro.',
    print_title: 'Inicia sesión o crea una cuenta para imprimir una oportunidad',
    print_subtitle: 'Para imprimir, regístrese o inicie sesión. ¡Es gratis!',
    refer_title: 'Registrarse para referir una oportunidad.',
    refer_subtitle: 'Para iniciar una referencia, regístrese o inicie sesión.¡Es gratis!',
    sharing_title: 'Haga compartir más facil',
    sharing_subtitle: 'Crear una cuenta gratuita para ahorrar y compartir recursos rápido y fácilmente en el futuro.',
    track_title: 'Cree una cuenta para empezar',
    track_subtitle: 'Cree una cuenta gratuita para empezar a hacer seguimiento de su acceso a estos recursos.',
    forgot_password_title: 'Restablecer contraseña',
    forgot_password_subtitle: 'Te enviaremos un correo electrónico o mensaje de texto para restablecer tu contraseña.',
    newCollection_title: 'Crear nueva colección',
    newCollection_subtitle: 'Necesita iniciar una sesión para crear una colección.',
    assessmentLogin_title: 'Iniciar una sesión',
    assessmentLogin_subtitle: 'Iniciar una sesión a su cuenta de One Degree para continuar.',
    assessmentSignup_title: '¡Ya está! Ahora regístrese para ver sus resultados',
    assessmentSignup_subtitle:
      'Cree una cuenta gratuita en One Degree para permitirnos verificar sus resultados y darle sugerencias sobre qué hacer a continuación.',
    Send: 'Enviar',
    'at least': 'Al menos',
    '8 characters': '8 caracteres',
    '1 letter': '1 letra',
    '1 number': '1 número',
    '1 special character': '1 caracter especial',
    password: 'Contraseña',
    'Already have an account?': 'Ya tiene una cuenta?',
    'Log in': 'Acceder',
    'Create an account': 'Cree una cuenta',
    'Finish account setup': 'Finalizar la configuración de su cuenta',
    'I work at a nonprofit or government agency': 'Trabajo en una organización sin fines de lucro o en una agencia del gobierno',
    'Sign up with your work email': 'Registrarse con su correo de trabajo',
    'Signup legal disclaimer':
      "Mantenemos su información confidencial. Se le preguntará si alguna vez necesitamos compartir su información con algún socio. Nunca vendemos sus datos. Aquí está nuestra <a href='${privacy_policy_link}' target='_blank'> Política de Privacidad </a> y nuestros <a href='${tou_link}' target='_blank'> Términos de Uso </a>, los cuales usted se compromete a aceptar al ",
    "Let's set up the details of your account": 'Configuremos los detalles de tu cuenta',
    'Set your location (required)': 'Establezca su ubicación (necesario)',
    'Location (required)': 'Ubicación (necesario)',
    Location: 'Ubicación',
    'Skip for now': 'Omitir esto por ahora',
    'We use your location to show relevant resources near you': 'Usamos su ubicación para mostrar recursos relevantes cerca de usted',
    Language: 'Idioma',
    English: 'Inglés',
    Spanish: 'Español',
    Continue: 'Continuar',
    set_organization_dhs_title: '¿Dónde haces la mayor parte de tu trabajo?',
    set_organization_dhs_subtitle: 'Seleccione uno incluso si trabaja en varios lugares.',
    'Gender (optional)': 'Género (opcional)',
    Male: 'Masculino',
    Female: 'Femenino',
    'Transgender Man': 'Hombre transgénero',
    'Transgender Woman': 'Mujer transgénero',
    'A gender not listed here': 'Un género no listado aquí',
    'Prefer not to say': 'Prefiero no decirlo',
    'Let me specify': 'Déjeme especificar',
    'Date of birth (optional)': 'Fecha de nacimiento (opcional)',
    'First name': 'Nombre',
    'Last name': 'Apellido',
    'Cell Phone': 'Teléfono celular',
    'Cell Phone (optional)': 'Teléfono celular (opcional)',
    'MM/DD/YYYY': 'MM/DD/AAAA',
    'Account created successfully.': 'Cuenta creada con éxito.',
    'The passwords should match': 'Las contraseñas deben coincidir',
    'Re-enter password': 'Escriba la contraseña otra vez',
    'Forgot password?': '¿Olvidó su contraseña?',
    "Don't have an account?": '¿No tienes una cuenta?',
    'Check your inbox or cell phone for instructions on how to reset your password. If you do not receive an email or text message from us, you may need to check your Spam folder or create a new One Degree account.':
      'Verifique su bandeja de entrada o teléfono celular para obtener instrucciones sobre cómo restablecer su contraseña. Si no recibe un mensaje de correo electrónico o un mensaje de texto de nuestra parte, es posible que deba consultar su carpeta de correo no deseado o crear una nueva cuenta en One Degree.',
    'Please check your email or cell phone and try again.': 'Por favor revise su correo electrónico o teléfono celular y vuelva a intentarlo.',
    'Which of the following genders best represents how you think of yourself?':
      '¿Cuál de los siguientes géneros representa mejor cómo piensas de si mismo?',
    Organization: 'Organización',
    'Organization (Start typing...)': 'Organización (Empiece a escribir...)',
    Position: 'Cargo',
    'Type to search...': 'Escriba para buscar...',
    'Searching...': 'Buscando...',
    'No matches found.': 'No se encontraron resultados.',
    Facilities: 'Instalaciones',
    'Main Agency': 'Agencia principal',
    "We couldn't create your account. Make sure you are using a real email address or cell phone number. If you think you already have an account, you can try logging in.":
      'No pudimos crear su cuenta. Asegúrese de estar utilizando una dirección de correo electrónico o un número de teléfono celular real. Si cree que ya tiene una cuenta, puede intentar iniciar sesión.',
    'You might not have an account yet. Please try signing up instead.': 'Es posible que aún no tenga una cuenta. Mejor intente registrarse',
    'Too many failed login attempts. Please confirm CAPTCHA and then try again.':
      'Demasiados intentos fallidos de iniciar sesión. Confirme CAPTCHA y vuelva a intentar.',
    'signup-error:email':
      'La dirección de correo electrónico que ha introducido ya ha sido utilizado. Utilice una dirección de correo electrónico diferente.',
    'signup-error:cell phone': 'El número de teléfono que ha introducido ya ha sido utilizado. Utilice un número de teléfono diferente.'
  })
};

export default withTranslations(translations);
