import { useState, useEffect } from 'react';
import { localStorageService } from '@/utils/local_storage';
import { deleteCookie, setCookie, getCookie } from '@/utils/cookies';
import omit from 'lodash/omit';

/**
 * Store information related to the active session that specifically should not
 * be stored another way (e.g. cookie) and needs to persist in browser memory.
 *
 * ex. SAML sessions need the "provider" to persist longer than the session cookies
 * so when the session times out we can know to show the SAML modal as opposed to
 * the general login modal.
 */

const STORAGE_KEY = 'one-degree:' + (typeof window !== 'undefined' ? window.location?.host : 'server') + ':session';

export const useLocalStorageSession = () => {
  const [sessionObject, setSessionObject] = useState(localStorageService.get(STORAGE_KEY) || {});

  // it's possible for the server to set the provider cookie and localStorage session won't have tha value
  // on initial run, check if provider is present to keep localStorage in sync with cookie.
  useEffect(() => {
    let provider = getCookie('provider');
    if (provider) {
      // already in cookies, no need to overwrite
      setSessionValue('provider', provider, false);
    }
  }, []);

  const setSessionValue = (key, value, saveAsCookie = true, cookieKey = false) => {
    let updatedSessionObject;
    if (typeof value === 'undefined') {
      updatedSessionObject = { ...omit(sessionObject, key) };
      setSessionObject(updatedSessionObject);
      if (saveAsCookie) {
        deleteCookie(cookieKey || key);
      }
    } else {
      if (saveAsCookie) {
        setCookie(cookieKey || key, value);
      }
      updatedSessionObject = { ...sessionObject };
      updatedSessionObject[key] = value;
      setSessionObject(updatedSessionObject);
    }
    return localStorageService.set(STORAGE_KEY, updatedSessionObject);
  };

  const deleteSessionValue = (key, saveAsCookie = true, cookieKey = false) => {
    return setSessionValue(key, 'undefined', saveAsCookie, cookieKey);
  };

  const clearLocalStorageSession = () => {
    localStorageService.remove(STORAGE_KEY);
  };

  return {
    clearLocalStorageSession,
    deleteSessionValue,
    sessionObject,
    setSessionValue
  };
};

export default useLocalStorageSession;
