import dynamic from 'next/dynamic';

const Modals = {
  AddToCollectionModal: dynamic(() => import('./AddToCollectionModal')),
  ChangeAccessEventStatusModal: dynamic(() => import('./ChangeAccessEventStatusModal')),
  ConfirmationModal: dynamic(() => import('./ConfirmationModal')),
  FilterListModal: dynamic(() => import('./FilterListModal')),
  FilterMobileModal: dynamic(() => import('./FilterMobileModal')),
  LoadingModal: dynamic(() => import('./LoadingModal')),
  InformationModal: dynamic(() => import('./InformationModal')),
  LoginModal: dynamic(() => import('./Login.modal')),
  ForgotPasswordModal: dynamic(() => import('./Signup/ForgotPassword.modal')),
  NewReviewModal: dynamic(() => import('./NewReviewModal')),
  PositiveReviewFeedbackModal: dynamic(() => import('./PositiveReviewFeedbackModal')),
  PrintModal: dynamic(() => import('./PrintModal')),
  RedirectModal: dynamic(() => import('./RedirectModal')),
  ConnectToSalesforceModal: dynamic(() => import('./ConnectToSalesforceModal')),
  RelatedResourcesModal: dynamic(() => import('./RelatedResourcesModal')),
  ShareResourceModal: dynamic(() => import('./ShareResourceModal')),
  SignupModal: dynamic(() => import('./Signup/Signup.modal')),
  ReloginModal: dynamic(() => import('./Relogin.modal')),
  UploadedDocumentModal: dynamic(() => import('./UploadedDocumentModal')),
  UpdateRegionModal: dynamic(() => import('./UpdateRegionModal')),
  UpdateLocationModal: dynamic(() => import('./UpdateLocationModal')),
  ViewDocumentModal: dynamic(() => import('./ViewDocumentModal'), { ssr: false }),
  EditClientModal: dynamic(() => import('./EditClientModal')),
  UpdateReferralModal: dynamic(() => import('./UpdateReferralModal')),
  ShareDocumentModal: dynamic(() => import('./ShareDocumentModal')),
  UpdateUserContactModal: dynamic(() => import('./UpdateUserContactModal')),
  NewAndEditCollectionModal: dynamic(() => import('./NewAndEditCollectionModal')),
  ConfirmationModal: dynamic(() => import('./ConfirmationModal')),
  LAHIPSurveyModal: dynamic(() => import('@/features/lahip/components/Modals/LAHIPSurveyModal')),
  SignupMemberDetails: dynamic(() => import('./Signup/SignupMemberDetails.modal')),
  SignupProDetails: dynamic(() => import('./Signup/SignupProDetails.modal')),
  ReferralNotificationModal: dynamic(() => import('./ReferralNotificationModal')),
  SignupAffiliationDetails: dynamic(() => import('./Signup/SignupAffiliationDetails.modal')),
  AssignReferralModal: dynamic(() => import('./AssignReferralModal')),
  ChangeLocationModal: dynamic(() => import('./ChangeLocationModal')),
  ErrorMessageModal: dynamic(() => import('./ErrorMessageModal/ErrorMessageModal')),
  UpdatePasswordModal: dynamic(() => import('./UpdatePasswordModal/UpdatePasswordModal')),
  ConfirmPhoneModal: dynamic(() => import('./ConfirmPhoneModal')),
  ChangeAssessmentLocationModal: dynamic(() => import('./ChangeAssessmentLocationModal')),
  DeleteAccountModal: dynamic(() => import('./DeleteAccountModal')),
  ChangeStaffPermissionsModal: dynamic(() => import('./ChangeStaffPermissionsModal/ChangeStaffPermissionsModal')),
  InviteStaffModal: dynamic(() => import('./InviteStaffModal/InviteStaffModal')),
  AddAdminModal: dynamic(() => import('./AddAdminModal/AddAdminModal')),
  SuggestEditModal: dynamic(() => import('./SuggestEditModal/SuggestEditModal')),
  SuggestDeleteResourceModal: dynamic(() => import('./SuggestDeleteResourceModal/SuggestDeleteResourceModal')),
  VerifyInformationModal: dynamic(() => import('./VerifyInformationModal/VerifyInformationModal')),
  ReferralAddOrEditRecipientModal: dynamic(() => import('./ReferralAddOrEditRecipientModal')),
  ReferralSentModal: dynamic(() => import('./ReferralSentModal/ReferralSentModal')),
  RemoveRestrictedReferralsModal: dynamic(() => import('./RemoveRestrictedReferralsModal')),
  AddNewCategoryModal: dynamic(() => import('./AddNewCategoryModal')),
  PasswordResetInstructionModal: dynamic(() => import('./PasswordResetInstructionModal/PasswordResetInstructionModal'))
};

export default Modals;
